import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as manage0AmEB2lXICMeta } from "/opt/buildhome/repo/pages/account/manage.vue?macro=true";
import { default as securityXOdRHh5FdmMeta } from "/opt/buildhome/repo/pages/account/security.vue?macro=true";
import { default as createKlzlxqlm8oMeta } from "/opt/buildhome/repo/pages/aml-check/buyer/create.vue?macro=true";
import { default as indexwZIVZvWsbAMeta } from "/opt/buildhome/repo/pages/aml-check/buyer/index.vue?macro=true";
import { default as create2pFinPWFVhMeta } from "/opt/buildhome/repo/pages/aml-check/seller/create.vue?macro=true";
import { default as indexgZNDQEPpS3Meta } from "/opt/buildhome/repo/pages/aml-check/seller/index.vue?macro=true";
import { default as _91id_93vORLVpdBhYMeta } from "/opt/buildhome/repo/pages/cases-histories/[id].vue?macro=true";
import { default as _91id_93zg9PwlXie9Meta } from "/opt/buildhome/repo/pages/cases/detail/[id].vue?macro=true";
import { default as indexcwoeRL3wXlMeta } from "/opt/buildhome/repo/pages/cases/index.vue?macro=true";
import { default as indexsQkBilcTjqMeta } from "/opt/buildhome/repo/pages/estate-agents-champion-leagues/index.vue?macro=true";
import { default as faqs2iv9uQ2ZFvMeta } from "/opt/buildhome/repo/pages/faqs.vue?macro=true";
import { default as forgot_45passwordcBWiw0xyOQMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as confirmwBBbt3iT79Meta } from "/opt/buildhome/repo/pages/gluedog/confirm.vue?macro=true";
import { default as finalise_45authCAqb6ofDGfMeta } from "/opt/buildhome/repo/pages/gluedog/finalise-auth.vue?macro=true";
import { default as identification_45checksOBujTabxP8Meta } from "/opt/buildhome/repo/pages/identification-checks.vue?macro=true";
import { default as income_45statsQs2h3nFQpTMeta } from "/opt/buildhome/repo/pages/income-stats.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as index7PSLXQQjIsMeta } from "/opt/buildhome/repo/pages/material-information-packs/index.vue?macro=true";
import { default as negotiators_45boardVLYAjV4uZlMeta } from "/opt/buildhome/repo/pages/negotiators-board.vue?macro=true";
import { default as negotiators_45leaguen1yr3tGEmTMeta } from "/opt/buildhome/repo/pages/negotiators-league.vue?macro=true";
import { default as quote_45appfOTcZHAOyqMeta } from "/opt/buildhome/repo/pages/quote-app.vue?macro=true";
import { default as quote_45casesaRXW2YnLJ0Meta } from "/opt/buildhome/repo/pages/quote-cases.vue?macro=true";
import { default as createFsYnzysWprMeta } from "/opt/buildhome/repo/pages/quote-requests/create.vue?macro=true";
import { default as indexktdrY5EALNMeta } from "/opt/buildhome/repo/pages/quote-requests/index.vue?macro=true";
import { default as _91token_93Kw08py977UMeta } from "/opt/buildhome/repo/pages/reset-password/[token].vue?macro=true";
import { default as index7H9AhSh0amMeta } from "/opt/buildhome/repo/pages/service-orders/aml/index.vue?macro=true";
import { default as video_45guidesdrsSdRpCTWMeta } from "/opt/buildhome/repo/pages/video-guides.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account",
    path: "/account",
    meta: indexy4DdxYIhQyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  },
  {
    name: "account-manage",
    path: "/account/manage",
    meta: manage0AmEB2lXICMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/manage.vue")
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securityXOdRHh5FdmMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/security.vue")
  },
  {
    name: "aml-check-buyer-create",
    path: "/aml-check/buyer/create",
    meta: createKlzlxqlm8oMeta || {},
    component: () => import("/opt/buildhome/repo/pages/aml-check/buyer/create.vue")
  },
  {
    name: "aml-check-buyer",
    path: "/aml-check/buyer",
    meta: indexwZIVZvWsbAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/aml-check/buyer/index.vue")
  },
  {
    name: "aml-check-seller-create",
    path: "/aml-check/seller/create",
    meta: create2pFinPWFVhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/aml-check/seller/create.vue")
  },
  {
    name: "aml-check-seller",
    path: "/aml-check/seller",
    meta: indexgZNDQEPpS3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/aml-check/seller/index.vue")
  },
  {
    name: "cases-histories-id",
    path: "/cases-histories/:id()",
    meta: _91id_93vORLVpdBhYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cases-histories/[id].vue")
  },
  {
    name: "cases-detail-id",
    path: "/cases/detail/:id()",
    meta: _91id_93zg9PwlXie9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/cases/detail/[id].vue")
  },
  {
    name: "cases",
    path: "/cases",
    meta: indexcwoeRL3wXlMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cases/index.vue")
  },
  {
    name: "estate-agents-champion-leagues",
    path: "/estate-agents-champion-leagues",
    component: () => import("/opt/buildhome/repo/pages/estate-agents-champion-leagues/index.vue")
  },
  {
    name: "faqs",
    path: "/faqs",
    meta: faqs2iv9uQ2ZFvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/faqs.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordcBWiw0xyOQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue")
  },
  {
    name: "gluedog-confirm",
    path: "/gluedog/confirm",
    meta: confirmwBBbt3iT79Meta || {},
    component: () => import("/opt/buildhome/repo/pages/gluedog/confirm.vue")
  },
  {
    name: "gluedog-finalise-auth",
    path: "/gluedog/finalise-auth",
    meta: finalise_45authCAqb6ofDGfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/gluedog/finalise-auth.vue")
  },
  {
    name: "identification-checks",
    path: "/identification-checks",
    meta: identification_45checksOBujTabxP8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/identification-checks.vue")
  },
  {
    name: "income-stats",
    path: "/income-stats",
    meta: income_45statsQs2h3nFQpTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/income-stats.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "material-information-packs",
    path: "/material-information-packs",
    meta: index7PSLXQQjIsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/material-information-packs/index.vue")
  },
  {
    name: "negotiators-board",
    path: "/negotiators-board",
    meta: negotiators_45boardVLYAjV4uZlMeta || {},
    component: () => import("/opt/buildhome/repo/pages/negotiators-board.vue")
  },
  {
    name: "negotiators-league",
    path: "/negotiators-league",
    meta: negotiators_45leaguen1yr3tGEmTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/negotiators-league.vue")
  },
  {
    name: "quote-app",
    path: "/quote-app",
    meta: quote_45appfOTcZHAOyqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-app.vue")
  },
  {
    name: "quote-cases",
    path: "/quote-cases",
    meta: quote_45casesaRXW2YnLJ0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases.vue")
  },
  {
    name: "quote-requests-create",
    path: "/quote-requests/create",
    meta: createFsYnzysWprMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-requests/create.vue")
  },
  {
    name: "quote-requests",
    path: "/quote-requests",
    meta: indexktdrY5EALNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-requests/index.vue")
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: _91token_93Kw08py977UMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reset-password/[token].vue")
  },
  {
    name: "service-orders-aml",
    path: "/service-orders/aml",
    meta: index7H9AhSh0amMeta || {},
    component: () => import("/opt/buildhome/repo/pages/service-orders/aml/index.vue")
  },
  {
    name: "video-guides",
    path: "/video-guides",
    meta: video_45guidesdrsSdRpCTWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/video-guides.vue")
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/request-quote",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/request-quotes",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/stats-summary",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/material-information-packs/listing",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/material-information-packs/listing-with-aml",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/material-information-packs/valuation",
    component: component_45stubGZr1UkTkg3
  }
]